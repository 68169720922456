.ToggleButtonGroup {
    margin: 10px;
}

.outerChartDiv {
    padding-bottom: 56.25%;
    position: relative;
    /* height: 0; */
    width: "85%";
    margin: "5%";
}

.innerChartDiv {
    position: absolute;
    top: 0;
    left: 20px;
    width: 95%;
    height: 100%;
    /* padding-bottom: 250px; */
}

.daySelector {
    padding-left: 20px;
    padding-right: 20px;
}

.h2Toggle {
    margin-bottom: 15px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}